import Head from "next/head";
import { NextSeo } from "next-seo";

import Piano from "components/piano/piano";

import { fetchCallouts, fetchNavbarAndFooter } from "lib/fetchers/common";
import { fetchArticles } from "lib/fetchers/articles";
import { PIANO_SCRIPT_URL, MIXPANEL_TOKEN } from "lib/constants";
import type { HomepageProps } from "lib/types/homepage";
import type { ArticleListRequestQuery } from "lib/types/article";
import { fetchHomePageData } from "content-ui/homepage/fetcher";

import { HomepageView } from "content-ui/homepage";
import {
  createSectorsList,
  getFeaturedArticlesMap,
  getFirstNumberOfUniqueArticles,
  updateIgnoreMappingWithArticlesFromList,
} from "content-ui/homepage/utils/featured";
import type { SectorType } from "content-ui/homepage/utils/featured";
import type { SeoData } from "lib/types/datoBlocks";

import ErrorBoundary from "components/errorBoundary/errorBoundary";
import AdManager from "components/thirdPartyScripts/adManager";
import { adConfigHome } from "components/thirdPartyScripts/adConfig";
import { upcomingTalks } from "content-ui/talks/utils";
import PreviewModeBanner from "components/previewModeBanner/previewModeBanner";
import Navigation from "components/navigation/navigation";
import Footer from "components/footer/footer";

export async function getServerSideProps({ res, params, preview = false }) {
  res.setHeader(
    "Cache-Control",
    "public, s-maxage=300, stale-while-revalidate=59"
  );

  const query: ArticleListRequestQuery = {
    page: 1,
    format: "abbreviated",
    placeholderImages: false,
  };

  const [sitewideOptionsNavbar, latest, dato, callouts] = await Promise.all([
    fetchNavbarAndFooter(),
    fetchArticles({ ...query, placeholderImages: true }),
    fetchHomePageData({ preview }),
    fetchCallouts(),
  ]);

  let sectors: SectorType[] = [];
  const { mappedArticles, briefingsLinks } = getFeaturedArticlesMap(
    sectors,
    dato.homepage.featuredArticles
  );

  const [articles, analysisArticles, news] = await Promise.all([
    fetchArticles({
      ...query,
      articles: Object.keys(mappedArticles).join(","),
    }),
    fetchArticles({
      ...query,
      articles: dato.homepage.analysisArticles
        .map((article) => article.articleId)
        .join(","),
    }),
    fetchArticles({
      ...query,
      taxonomy: dato.homepage.featuredSidebarTaxonomy,
      term_slug: dato.homepage.featuredSidebarTag,
    }),
  ]);

  if (articles && articles.data) {
    sectors = createSectorsList(
      articles.data.articles,
      mappedArticles,
      sectors,
      briefingsLinks
    );
  }

  const latestNews = getFirstNumberOfUniqueArticles(
    news.data?.articles,
    4,
    {} // do not de-duplicate articles for NEWS section
  );

  // for latest articles ignore only "Featured All" section and "Latest news"
  const ignoreArticles = {};
  const featuredArticlesDefault =
    dato.homepage.featuredArticles[0].featuredArticle;

  featuredArticlesDefault.forEach((el) => {
    if (el.__typename === "ArticleRecord") {
      ignoreArticles[el.articleId || el.articleSlug || ""] =
        mappedArticles[el.articleId || el.articleSlug || ""];
    }
  });

  const latestArticles = getFirstNumberOfUniqueArticles(
    latest.data?.articles,
    5,
    updateIgnoreMappingWithArticlesFromList(ignoreArticles, latestNews)
  );

  const analysis = analysisArticles?.data?.articles || [];
  const talks = upcomingTalks(dato.talksPage?.talksData).slice(0, 1);

  return {
    props: {
      __initialState: {
        homepage: {
          sectors,
          latestNews,
          latest: latestArticles,
          analysis,
          talks,
          dato: {
            homepage: dato.homepage,
          },
        } as HomepageProps,
        sitewideOption: {
          ...dato.sitewideOption,
          ...sitewideOptionsNavbar,
          ...callouts,
        },
      },
      seo: dato.homepage.seo,
      previewMode: preview,
    },
  };
}

type PageProps = {
  seo: SeoData["seo"];
  previewMode: boolean;
};

const Homepage = (props: PageProps) => {
  const title = props.seo!.title || "Homepage";
  const description =
    props.seo!.description ||
    "The latest startup news, analysis and in-depth reporting on Europe's founders, investors and operators. Backed by the Financial Times.";
  const seoImage = props.seo!.image.url || "";

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          title,
          description,
          images: [{ url: seoImage }],
        }}
        twitter={{
          handle: "@siftedeu",
          site: "@siftedeu",
          cardType: "summary_large_image",
        }}
      />

      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <AdManager config={adConfigHome} />

      <Piano pianoScriptUrl={PIANO_SCRIPT_URL} mixpanelToken={MIXPANEL_TOKEN} />

      <Navigation />

      {props.previewMode ? <PreviewModeBanner exitSlug={`/`} /> : null}

      <ErrorBoundary>
        <HomepageView />
      </ErrorBoundary>
      <Footer />
    </>
  );
};

export default Homepage;
