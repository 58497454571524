import ArticleCard from "components/articleCard/articleCard";
import type { ArticleShort } from "lib/types/article";

const MAX_TITLE_LENGTH_FOR_EXCERPT = 60;

type MixPanelData = {
  [key: string]: any;
};

type SidebarCollectionProps = {
  heading: string;
  collection: ArticleShort[];
  emptyMessage: string;
  gaArticleClassName?: string;
  gaArticleSectorClassName?: string;
  onArticleClick: (data: MixPanelData, type: "url" | "sector") => Promise<void>;
};

export const SidebarCollection = ({
  heading,
  collection,
  emptyMessage,
  onArticleClick,
  gaArticleSectorClassName,
  gaArticleClassName,
}: SidebarCollectionProps) => {
  return (
    <div className={"relative"}>
      <h2 className={"mb-3 font-bold leading-[120%]"}>{heading}</h2>
      {collection.length > 0 ? (
        <ul
          className={
            "grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-1 lg:gap-4"
          }
          aria-label={`List of ${heading.toLowerCase()} articles`}
        >
          {collection.map((collectionItem) => {
            return (
              <li className={"h-[189px]"} key={collectionItem.id}>
                <ArticleCard
                  mainLinkClasses={gaArticleClassName}
                  sectorClasses={gaArticleSectorClassName}
                  variant={"small"}
                  withImage={false}
                  truncateAuthors={true}
                  withExcerpt={
                    collectionItem.title.length < MAX_TITLE_LENGTH_FOR_EXCERPT
                  }
                  onTrackingEventClicked={onArticleClick}
                  {...collectionItem}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={"w-100 text-center text-[14px] text-[#707073]"}>
          {emptyMessage}
        </div>
      )}
    </div>
  );
};
