import { RefObject, useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";

/**
 * Extended react-use useIntersection to only trigger the callback once.
 * Instead of an intersection it just returns a boolean whether it had intersected or not
 */
export const useIntersectedOnce = (
  ref: RefObject<HTMLElement>,
  options: IntersectionObserverInit
): boolean => {
  const mockRef = useRef(null);
  const [intersectedOnce, setIntersectedOnce] = useState(false);
  const intersection = useIntersection(
    /**
     * By conditionally passing the ref to the useIntersection hook, we can disable the hook
     * @see https://github.com/streamich/react-use/blob/master/src/useIntersection.ts#L11
     */
    intersectedOnce ? mockRef : ref,
    options
  );

  useEffect(() => {
    if (!intersectedOnce && intersection?.isIntersecting) {
      setIntersectedOnce(true);
    }
  }, [intersectedOnce, intersection?.isIntersecting]);

  return intersectedOnce;
};
