import { useHomepageDatoProps } from "lib/store/homepage";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import { getDateFormattedNumeric } from "lib/utils";

import FeatureCard from "components/featureCard/featureCard";
import TextButton from "components/textButton/textButton";

export const Briefings = () => {
  const { track } = useMixpanelTracking();
  const data = useHomepageDatoProps();

  return (
    <section className="h-100 w-100 border-t-2 border-black-rock pb-[30px]">
      <div className="flex h-[45px] items-center justify-between">
        <h3 className="font-bold">Briefings</h3>
        <div>
          <TextButton
            component="link"
            colour="black"
            className="ga-briefing-pro-link w-fit"
            withIcon={true}
            href={data.briefingsProButtonUrl}
            onClick={async () => {
              await track("Briefings button click", {
                "Click text": data.briefingsProButtonText,
                "Click link": data.briefingsProButtonUrl,
              });
            }}
          >
            {data.briefingsProButtonText}
          </TextButton>
        </div>
      </div>
      <ul className={"grid grid-cols-1 gap-[30px] md:grid-cols-2"}>
        {data.featuredBriefingsList.map((el, index) => {
          return (
            <li key={el.id} className={"h-[395px] last:hidden last:md:block"}>
              <FeatureCard
                component="link"
                href={el.url}
                title={el.title}
                wrapperClasses={"h-full"}
                theme={"pro"}
                excerpt={el.subtitle}
                leftLabel={el.marketLabel}
                rightLabel={getDateFormattedNumeric(el.publishTimestamp)}
                backgroundImage={{
                  ...el.briefingImage,
                  sizes:
                    "(max-width: 768px) 500px, (max-width: 1200px) 500px, 500px",
                }}
                withPaywall={false}
                onClick={async () => {
                  await track("Briefings featured click", {
                    "Click text": el!.title,
                    "Click link": el!.url,
                    Card: index + 1,
                  });
                }}
              />
            </li>
          );
        })}
      </ul>
    </section>
  );
};
