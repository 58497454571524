import Image from "components/imageNew/image";
import TextButton from "components/textButton/textButton";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import { useHomepageDatoProps } from "lib/store/homepage";

export const Reports = () => {
  const data = useHomepageDatoProps();
  const { track } = useMixpanelTracking();

  return (
    <section className="border-black-rock w-100 border-t-2 pb-[30px] lg:max-w-[320px]">
      <div className="flex h-[45px] items-center justify-between">
        <h3>Reports</h3>
        <TextButton
          className="ga-reports-text-link"
          colour="black"
          href={data.reportsCtaUrl}
          withIcon={true}
          onClick={async () => {
            await track("Homepage link click", {
              "Click link": data.reportsCtaUrl,
              "Click text": data.reportsCtaText,
              Component: "Reports",
            });
          }}
        >
          {data.reportsCtaText}
        </TextButton>
      </div>

      <a
        href={data.reportUrl}
        className="ga-report-link"
        onClick={async () => {
          await track("Report click", {
            "Click link": data.reportUrl,
          });
        }}
      >
        <Image
          src={data.reportImage.url}
          height={data.reportImage.height}
          width={data.reportImage.width}
          blurDataURL={data.reportImage.blurUpThumb}
          alt={data.reportImage.alt || ""}
          className="mx-auto h-[450px] w-100 max-w-[100%] object-contain md:mx-0 md:h-[465px] lg:h-[450px]"
        />
      </a>
    </section>
  );
};
