import classNames from "classnames";
import TextButton from "components/textButton/textButton";
import ArticleCard from "components/articleCard/articleCard";
import { useHomepageData, useHomepageDatoProps } from "lib/store/homepage";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";

const MAX_TITLE_LENGTH_FOR_EXCERPT = 60;

type MixPanelData = {
  [key: string]: any;
};

type ActionProps<T extends {} = {}> = T & {
  url?: string;
  text?: string;
  component?: string;
  event?: string;
};

export const Analysis = () => {
  const { analysis } = useHomepageData();
  const data = useHomepageDatoProps();
  const { track } = useMixpanelTracking();

  const handleArticleClick = async ({
    properties,
    component,
    event,
  }: ActionProps<{ properties: MixPanelData }>) => {
    event &&
      (await track(event, {
        ...properties,
        Component: component,
      }));
  };

  return (
    <section className="border-black-rock h-100 w-100 border-t-2 pb-[30px]">
      <div className="flex h-[45px] items-center justify-between">
        <h3 className="font-bold">Analysis</h3>
        <TextButton
          className="ga-analysis-text-link"
          colour="black"
          href={data.analysisCtaUrl}
          withIcon={true}
          onClick={async () => {
            await track("Homepage link click", {
              "Click link": data.analysisCtaUrl,
              "Click text": data.analysisCtaText,
              Component: "Analysis",
            });
          }}
        >
          {data.analysisCtaText}
        </TextButton>
      </div>

      <ul
        className={classNames(
          "grid grid-cols-1 gap-x-[20px] gap-y-[16px] md:grid-cols-2 lg:grid-cols-1"
        )}
        aria-label={`List of all the latest articles`}
      >
        {analysis &&
          analysis.slice(0, 2).map((article) => {
            return (
              <li key={article.id} className={classNames("h-[189px]")}>
                <ArticleCard
                  mainLinkClasses={"ga-analysis-article"}
                  sectorClasses={"ga-analysis-article-sector"}
                  variant={"small"}
                  withImage={article.layout !== "short_form"}
                  withExcerpt={
                    article.layout === "short_form" &&
                    article.title.length < MAX_TITLE_LENGTH_FOR_EXCERPT
                  }
                  onTrackingEventClicked={async (data, type) =>
                    await handleArticleClick({
                      event:
                        type === "sector"
                          ? "Homepage article sector click"
                          : "Homepage article click",
                      properties: data,
                      component: "Analysis",
                    })
                  }
                  {...article}
                />
              </li>
            );
          })}
      </ul>
    </section>
  );
};
