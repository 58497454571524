import classNames from "classnames";
import dynamic from "next/dynamic";

const AdManagerNoSSR = dynamic(() => import("./mpu"), {
  ssr: false,
});

const AdUnit = ({ AdUnitID }: { AdUnitID: string }) => (
  <div className={classNames("flex flex-col items-center")}>
    <div className="min-h-[250px]">
      <AdManagerNoSSR AdUnitID={AdUnitID} />
    </div>
  </div>
);

export default AdUnit;
