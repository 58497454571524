import { useEffect, useState } from "react";

import useSiftedMediaQueries from "lib/hooks/useSiftedMediaQueries";
import { useHomepageDatoProps } from "lib/store/homepage";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import Image from "components/imageNew/image";
import { SingleSlideCarousel } from "components/carousel/singleSlide";

const LinkedImage = ({ imageData, mobile }) => {
  return (
    <>
      {mobile ? (
        <Image
          width={imageData?.mobileImage?.width}
          height={imageData?.mobileImage?.height}
          src={imageData?.mobileImage?.url}
          alt={imageData?.mobileImage?.alt || ""}
          blurDataURL={imageData?.mobileImage?.blurUpThumb}
          className="pointer-events-none h-100 w-100 object-cover"
          quality={80}
          sizes="400px"
        />
      ) : (
        <Image
          width={imageData?.desktopTabletImage?.width}
          height={imageData?.desktopTabletImage?.height}
          src={imageData?.desktopTabletImage?.url}
          alt={imageData?.desktopTabletImage?.alt || ""}
          blurDataURL={imageData?.desktopTabletImage?.blurUpThumb}
          className="pointer-events-none h-100 w-100 object-cover"
          quality={80}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 1220px"
        />
      )}
    </>
  );
};

export const Events = () => {
  const { events } = useHomepageDatoProps();
  const { track } = useMixpanelTracking();

  const { mobile } = useSiftedMediaQueries();
  const [isHydrated, setIsHydrated] = useState(false);

  // Wait until after client-side hydration to show
  useEffect(() => {
    setIsHydrated(true);
  }, []);

  if (events.length === 0 || !isHydrated) return null;
  if (events.length === 1)
    return (
      <a href={events[0].url} className="ga-event-link">
        <LinkedImage imageData={events[0]} mobile={mobile} />
      </a>
    );

  const slidesSingle = events.map((item) => {
    return {
      component: (
        <div className="ga-event-link h-100 w-100 cursor-pointer">
          <LinkedImage imageData={item} mobile={mobile} />
        </div>
      ),
      onClick: async () => {
        await track("Events slide click", {
          "Click link": item.url,
        });

        window.location.assign(item.url);
      },
    };
  });

  return (
    <div className="mt-[30px]">
      <h2 className="mb-3 text-[18px] font-bold">Events</h2>
      <SingleSlideCarousel
        slides={slidesSingle}
        containerClasses="h-[450px] max-md:w-[320px] max-md:mx-auto md:h-[380px]"
        slideClasses=""
      />
    </div>
  );
};
