import classNames from "classnames";
import Image from "components/imageNew/image";
import TextButton from "components/textButton/textButton";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import { useHomepageDatoProps } from "lib/store/homepage";
import { Marquee } from "components/marquee/marquee";
import type { DatoImageType } from "lib/types";

type PodcastProps = {
  name: string;
  image: DatoImageType;
  link: string;
};

export const PodcastsSection = () => {
  const { track } = useMixpanelTracking();
  const data = useHomepageDatoProps();

  return (
    <section data-testid="podcastSection">
      <div className="mt-[65px] pb-3xl md:mx-auto">
        <div className="inline-flex w-100 items-center justify-between">
          <h2 className="font-bold">Podcasts</h2>
          <TextButton
            size={"medium"}
            colour={"cerise"}
            href={data.podcastsExploreLink}
            className="ga-podcasts-text-link text-inherit"
            withIcon={true}
            onClick={async () => {
              await track("Homepage link click", {
                "Click link": data.podcastsExploreLink,
                "Click text": data.podcastsExploreLinkText,
                Component: "Podcasts",
              });
            }}
          >
            {data.podcastsExploreLinkText}
          </TextButton>
        </div>

        <Marquee animationSpeed={8}>
          {data.podcasts.map((podcast: PodcastProps, index: number) => (
            <Podcast
              key={`podcast_${index}`}
              image={podcast.image}
              name={podcast.name}
              link={podcast.link}
            />
          ))}
        </Marquee>
      </div>
    </section>
  );
};

const Podcast = ({ image, name, link }: PodcastProps) => {
  const titleLength = name.split("").length;

  return (
    <li
      className={classNames("mx-[50px]", {
        "w-[550px]": titleLength > 80,
        "w-[475px]": titleLength > 60 && titleLength <= 80,
        "w-[400px]": titleLength <= 60,
      })}
    >
      <a href={link} className="flex">
        <Image
          src={image?.url || ""}
          alt={image?.alt || ""}
          className={"mr-[12px] h-[80px] w-[80px]"}
          width={image?.width}
          height={image?.height}
          blurDataURL={image.blurUpThumb}
          quality={75}
          sizes="300px"
          blurUp={false}
          priority={true}
        />

        <div className="flex flex-col items-baseline justify-start">
          <h3 className="text-[14px] leading-[1.138]">Podcast</h3>
          <p className="font-bold leading-[24px]">{name}</p>
        </div>
      </a>
    </li>
  );
};
