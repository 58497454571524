import React, { useContext } from "react";
import classNames from "classnames";

import { TabsContext } from "../tabs";

type TabPanelProps = {
  children: string | React.ReactNode;
  value: string | number;
};

export const TabPanel = ({ children, value }: TabPanelProps) => {
  const tabsProps = useContext(TabsContext);

  if (!tabsProps) return null;

  const isActive = value === tabsProps.active;

  return (
    <div
      role={"tabpanel"}
      id={`panel-${value}`}
      aria-labelledby={`tab-${value}`}
      tabIndex={isActive ? 0 : -1}
      className={classNames(
        "focus-visible:ring-black-rock focus:outline-none focus-visible:ring",
        {
          hidden: !isActive,
        }
      )}
      hidden={isActive ? undefined : true}
    >
      {children}
    </div>
  );
};
