import { MultiSlideCarousel } from "components/carousel/multiSlide";
import { HeroCarouselCard } from "./carouselCard";
import { useHomepageDatoProps } from "lib/store/homepage";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";

export const Hero = () => {
  const { heroCarousel } = useHomepageDatoProps();
  const { track } = useMixpanelTracking();

  const slidesMulti = heroCarousel.map((item) => {
    const widths = item.imageOnly || !item.image?.url ? [320] : [553, 553, 320];

    return {
      widths,
      component: (
        <div className="ga-hero-article h-100 w-100 cursor-pointer">
          <HeroCarouselCard data={item} />
        </div>
      ),
      onClick: async () => {
        await track("Hero click", {
          title: item.title,
          "Click link": item.link,
          "Click text": item.cta,
        });

        window.location.assign(item.link);
      },
    };
  });

  return (
    <div className="flex h-[335px] select-none flex-col [-webkit-user-select:none] max-md:mb-[30px] md:h-[393px] lg:h-[237px] lg:flex-row">
      <div className="w-100 lg:w-[45%]">
        <h1 className="text-right font-recklessBold text-[30px] leading-[130%] max-lg:mb-[20px] max-[300px]:text-[9.5vw] md:text-left md:text-[52px]">
          <span className="block">On startup Europe,</span>
          <span>for startup Europe</span>
        </h1>
      </div>
      <div className="w-100 lg:w-[55%]">
        <div className="relative flex h-100 w-100 justify-center">
          <MultiSlideCarousel
            slides={slidesMulti}
            containerClasses="h-[220px] w-100"
            slideClasses="h-[200px] top-[10px]"
            responsiveGap={[20]}
            timerMS={6000}
            onlyCenterInteractive={true}
          />
          <div className="absolute left-[-1px] top-0 hidden h-100 w-[81px] bg-gradient-to-l from-[#f6f6f600] to-[#F6F6F6] md:block"></div>
          <div className="absolute right-[-1px] top-0 hidden h-100 w-[81px] bg-gradient-to-r from-[#f6f6f600] to-[#F6F6F6] md:block"></div>
        </div>
      </div>
    </div>
  );
};
