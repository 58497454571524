import React, { ForwardedRef, useContext } from "react";
import classNames from "classnames";
import { m } from "framer-motion";

import { TabsContext } from "../tabs";

type TabProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  value: string | number;
  children: string;
  onNext?: (currentTab: string | number) => void;
  onPrev?: (currentTab: string | number) => void;
  component?: "button" | "link";
  href?: string;
  noUnderlineAnimation?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>
  ) => Promise<void> | void;
};

export const Tab = React.forwardRef(
  (
    {
      value,
      children,
      onClick,
      onNext,
      onPrev,
      className,
      component = "button",
      href,
      noUnderlineAnimation,
      ...rest
    }: TabProps,
    ref: ForwardedRef<any>
  ) => {
    const tabsProps = useContext(TabsContext);

    if (!tabsProps) return null;

    const { onChange, active } = tabsProps;
    const isActive = active === value;

    const handleClick = async (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.MouseEvent<HTMLAnchorElement>
    ) => {
      e.preventDefault();
      onChange(value, active, {
        ...tabsProps,
        active: value,
      });
      onClick && (await onClick(e));
    };

    const handleKeyDown = (
      event:
        | React.KeyboardEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLAnchorElement>
    ) => {
      const keyMap = {
        ArrowRight: onNext,
        ArrowLeft: onPrev,
      };

      const action = keyMap[event.key];

      if (action) {
        event.preventDefault();
        action(value);
      }
    };

    const commonProps = {
      role: "tab",
      "aria-selected": isActive,
      "aria-controls": `panel-${value}`,
      id: `tab-${value}`,
      tabIndex: isActive ? 0 : -1,
      onClick: handleClick,
      onKeyDown: handleKeyDown,
      className: classNames(
        "relative inline-flex shrink-0 flex-col items-center",
        "px-4 pb-[13px] pt-[11px] text-[18px] leading-[150%] tracking-[-0.022em] transition-colors ",
        "hover:text-black-rock focus:outline-none focus-visible:ring focus-visible:ring-inset focus-visible:ring-black-rock",
        {
          "text-[#707073]": !isActive,
          "font-bold text-black-rock": isActive,
        },
        className
      ),
    };

    const innerText = () => {
      return (
        <>
          <span>{children}</span>
          <span className={"invisible h-0 font-bold"} aria-hidden={"true"}>
            {children}
          </span>
          {isActive ? (
            <m.div
              className={classNames(
                "bg-black-rock absolute bottom-0 left-0 right-0 h-[2px]"
              )}
              layoutId={!noUnderlineAnimation ? "underline" : undefined}
            />
          ) : null}
        </>
      );
    };

    if (component === "link" && href) {
      return (
        <a ref={ref} href={href} {...commonProps}>
          {innerText()}
        </a>
      );
    }

    return (
      <button ref={ref} {...commonProps} {...rest}>
        {innerText()}
      </button>
    );
  }
);
