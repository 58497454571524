import classNames from "classnames";
import { MdChevronRight } from "react-icons/md";
import Image from "components/imageNew/image";

const Cta = ({ cta, className }) => {
  if (!cta) return null;
  return (
    <div className={classNames(className, "items-end text-cerise")}>
      <p className="text-[14px] font-bold">{cta}</p>
      <MdChevronRight size={"1.4em"} className="ml-[2px] translate-y-[3px]" />
    </div>
  );
};

export const HeroCarouselCard = ({ data }) => {
  const { imageOnly } = data;
  const textOnly = !data.imageOnly && !data.image?.url;

  return (
    <div
      data-testid="carousel-card"
      className={classNames(
        "relative flex h-[200px] flex-col justify-between bg-white",
        {
          "w-[320px] p-[14px] md:w-[553px]": !imageOnly,
        }
      )}
    >
      <div className="flex gap-x-[10px] md:h-100">
        <div className="absolute left-0 top-0 z-[1] h-[17px] w-[17px] bg-cerise [clip-path:polygon(0_0,_0_100%,_100%_0)]"></div>

        {imageOnly ? null : (
          <div
            className={classNames("flex h-100 flex-col justify-between", {
              "w-7/12 md:w-1/2": !textOnly,
              "w-[290px]": textOnly,
            })}
          >
            <div>
              <p className="text-[14px] font-bold text-[#EA8DB0]">
                {data.title}
              </p>
              <p className="mt-2 line-clamp-5 font-recklessBold text-[16px] leading-[130%]">
                {data.description}
              </p>
            </div>
            <Cta cta={data.cta} className={"hidden md:flex"} />
          </div>
        )}

        <div
          className={classNames({
            "w-5/12 md:w-1/2": !imageOnly && !textOnly,
          })}
        >
          <Image
            width={data.image?.width}
            height={data.image?.height}
            src={data.image?.url}
            blurDataURL={data.image?.blurUpThumb}
            alt={data.image?.alt}
            sizes="(max-width: 768px) 400px, (max-width: 1200px) 400px, 400px"
            className={classNames("pointer-events-none object-cover", {
              "mt-[26px] h-[80px] w-[120px] md:mt-0 md:h-[172px] md:w-[257px]":
                !imageOnly,
              "h-[200px] w-[320px] object-center": imageOnly,
            })}
            priority={true}
          />
        </div>
      </div>

      {imageOnly ? null : <Cta cta={data.cta} className={"flex md:hidden"} />}
    </div>
  );
};
