import classNames from "classnames";

import { useHomepageDatoProps } from "lib/store/homepage";
import Button from "components/buttonNew/button";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import { NewsletterHomepageCard } from "./components/newsletterHomepageCard/newsletterHomepageCard";

export const Newsletters = () => {
  const data = useHomepageDatoProps();
  const { track } = useMixpanelTracking();

  return (
    <div
      className={classNames(
        "relative left-50 right-50 flex h-auto flex-col items-center bg-[#E6E6EB]",
        "mx-[calc(var(--vw,_1vw)_*_-50)] mb-[80px] w-[calc(var(--vw,_1vw)_*_100)] md:mb-[70px]"
      )}
    >
      <div
        className={
          "w-full max-w-[1140px] px-5 md:mx-auto lg:max-w-[1220px] lg:px-0"
        }
      >
        <h2 className="mb-[20px] mt-[70px] font-bold text-[22px] leading-[120%] md:text-[28px]">
          {data.newsletterSectionHeading}
        </h2>

        <div
          className={
            "grid w-full gap-x-5 gap-y-5 md:grid-cols-2 lg:grid-cols-3"
          }
        >
          {data.newslettersList?.map((newsletter) => {
            return (
              <NewsletterHomepageCard
                key={newsletter.id}
                {...newsletter}
                newslettersProSubscribeUrl={data.newslettersProSubscribeUrl}
              />
            );
          })}
        </div>
        <div className="mb-[70px] mt-[40px] flex items-center justify-center">
          <Button
            size={"medium"}
            className="ga-newsletters-text-link"
            component="button"
            colour="cerise"
            onClick={async () => {
              await track("Homepage link click", {
                "Click link": data.newsletterCtaUrl,
                "Click text": data.newsletterCtaText,
                Component: "Newsletters",
              });
              window.location.assign(data.newsletterCtaUrl);
            }}
          >
            {data.newsletterCtaText}
          </Button>
        </div>
      </div>
    </div>
  );
};
