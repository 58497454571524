import { shallow } from "zustand/shallow";

import type { HomepageDatoProps, HomepageProps } from "lib/types/homepage";

import { useStore } from "./storeProvider";
import type { StoreState } from "./index";

type HomepageHelpers = Pick<StoreState, "homepage" | "setHomepage">;

export const useHomepage = (): HomepageHelpers => {
  const { homepage, setHomepage } = useStore(
    (store) => ({
      homepage: store.homepage,
      setHomepage: store.setHomepage,
    }),
    shallow
  );

  return { homepage, setHomepage };
};

export const useHomepageData = (): HomepageProps => {
  const { homepage } = useStore(
    (store) => ({
      homepage: store.homepage,
    }),
    shallow
  );

  return { ...homepage! };
};

export const useHomepageDatoProps = (): HomepageDatoProps => {
  const { homepage } = useStore(
    (store) => ({
      homepage: store.homepage,
    }),
    shallow
  );

  return { ...homepage?.dato?.homepage! };
};
