export type AdConfigType = {
  ad_unit: string;
  page_targeting: [string, string][] | [];
  slots: {
    slot_id: string;
    sizes: [number, number][];
    targeting: [string, string][];
    size_mapping: Array<Array<Array<number[] | number>>>;
  }[];
};

const adConfig = (
  adUnit: string,
  pageTargeting: [string, string][] | []
): AdConfigType => {
  return {
    ad_unit: adUnit,
    page_targeting: pageTargeting,
    slots: [
      {
        sizes: [[300, 250]],
        targeting: [["pos", "0"]],
        slot_id: "ad_slot_1",
        size_mapping: [
          [[1280, 400], [[300, 250]]],
          [[0, 0], []],
        ],
      },
      {
        sizes: [[300, 250]],
        targeting: [["pos", "1"]],
        slot_id: "ad_slot_2",
        size_mapping: [
          [[1280, 400], [[300, 250]]],
          [[0, 0], []],
        ],
      },
      {
        slot_id: "ad_slot_mobile_1",
        sizes: [[300, 250]],
        targeting: [["pos", "0"]],
        size_mapping: [
          [[1280, 768], []],
          [
            [0, 0],
            [300, 250],
          ],
        ],
      },
      {
        slot_id: "ad_slot_mobile_2",
        sizes: [[300, 250]],
        targeting: [["pos", "1"]],
        size_mapping: [
          [[1280, 768], []],
          [
            [0, 0],
            [300, 250],
          ],
        ],
      },
    ],
  };
};

export const adConfigHome = {
  ad_unit: "/Home",
  slots: [
    {
      sizes: [
        [300, 250],
        [300, 250],
      ],
      slot_id: "ad_slot_0",
      size_mapping: [
        [
          [1276, 768],
          [
            [300, 250],
            [300, 250],
          ],
        ],
        [
          [0, 0],
          [300, 250],
        ],
      ],
      targeting: [["pos", "0"]],
    },
  ],
} as AdConfigType;

export const adConfigSectors = {
  ad_unit: "/Archives",
  page_targeting: [
    ["page_type", "taxonomy_archive"],
    ["taxonomy", "sector"],
  ],
  slots: [
    {
      sizes: [
        [300, 250],
        [300, 600],
      ],
      slot_id: "ad_slot_0",
      size_mapping: [
        [
          [1276, 768],
          [
            [300, 250],
            [300, 600],
          ],
        ],
        [
          [0, 0],
          [300, 250],
        ],
      ],
      targeting: [["pos", "0"]],
    },
  ],
} as AdConfigType;

export const adConfigCountries = {
  ad_unit: "/Archives",
  page_targeting: [
    ["page_type", "taxonomy_archive"],
    ["taxonomy", "country"],
  ],
  slots: [
    {
      sizes: [
        [300, 250],
        [300, 600],
      ],
      slot_id: "ad_slot_0",
      size_mapping: [
        [
          [1276, 768],
          [
            [300, 250],
            [300, 600],
          ],
        ],
        [
          [0, 0],
          [300, 250],
        ],
      ],
      targeting: [["pos", "0"]],
    },
  ],
} as AdConfigType;

export default adConfig;
