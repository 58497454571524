const NewsletterCardSkeleton = () => {
  return (
    <div
      className="relative flex h-full min-h-[240px] w-full animate-pulse flex-col justify-between gap-[30px] bg-white p-5 lg:min-h-[281px] lg:gap-5"
      role="status"
      aria-live="polite"
      aria-hidden="true"
      data-testid={"newsletterCardSkeleton"}
    >
      <div className="static">
        <div className="flex flex-row items-start">
          <div className="h-6 w-6 bg-gray-300" aria-hidden="true"></div>
          <div className="flex flex-1 items-center justify-between gap-5">
            <div className="h-6 w-1/2 bg-gray-300" aria-hidden="true"></div>
            <div className="h-4 w-1/4 bg-gray-300" aria-hidden="true"></div>
          </div>
        </div>
        <p className="mt-[10px] h-4 w-full bg-gray-300" aria-hidden="true"></p>
      </div>
      <div className="flex flex-col items-center justify-between gap-5 lg:flex-row lg:items-end lg:gap-0">
        <div
          className="h-[120px] w-[161px] bg-gray-300"
          aria-hidden="true"
        ></div>
      </div>
    </div>
  );
};

export default NewsletterCardSkeleton;
