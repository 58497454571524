import TextButton from "components/textButton/textButton";
import type { TalkData } from "content-ui/talks/types";
import { upcomingTalks } from "content-ui/talks/utils";
import { useHomepageData, useHomepageDatoProps } from "lib/store/homepage";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import { EventCard } from "components/eventCard/eventCard";
import { TalkBody } from "components/eventCard/talkElements";
import TalkRegistration from "content-ui/talks/components/registration/talkRegistration/talkRegistration";
import { AddToCalendar } from "components/addToCalendar/addToCalendar";

export const TalksSection = () => {
  const { talks } = useHomepageData();
  const data = useHomepageDatoProps();
  const { track } = useMixpanelTracking();

  return (
    <section className="mt-[30px] w-100">
      <div className="mb-3 flex w-100 items-center justify-between">
        <h3 className="font-bold text-[18px]">Upcoming Talks</h3>
        <TextButton
          className="ga-talks-text-link"
          colour="black"
          href={"/talks"}
          withIcon={true}
          onClick={async () => {
            await track("Homepage link click", {
              "Click link": data.talksCtaUrl,
              "Click text": data.talksCtaText,
              Component: "Talks",
            });
          }}
        >
          {data.talksCtaText}
        </TextButton>
      </div>

      {talks &&
        upcomingTalks(talks)
          .slice(0, 1)
          .map((talk: TalkData) => {
            return (
              <EventCard
                key={talk.id}
                cardLink={`/talks/${talk.slug}`}
                image={talk.image}
                sponsorImage={talk?.sponsorLogo}
                heading={talk.marketLabel}
                title={talk.title}
                body={<TalkBody talk={talk} />}
                registration={<TalkRegistration talk={talk} />}
                eventDateTime={new Date(talk.dateTime)}
                variant="talk"
                excerpt={talk.excerpt}
                calendar={
                  <AddToCalendar
                    event={talk}
                    classNames="text-[#767683] [&>span]:ml-md space-x-[30px]"
                    type="talk"
                  />
                }
              />
            );
          })}
    </section>
  );
};
