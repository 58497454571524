import React, { createContext, useState } from "react";
import classNames from "classnames";

import { Tab } from "./tab/tab";
import { TabPanel } from "./tabPanel/tabPanel";
import { TabsList } from "./tabsList/tabsList";
import { TabsContainer } from "./tabsContainer/tabsContainer";

type TabsProps = {
  active: string | number | null;
  children: React.ReactNode;
  onChange?: (
    newValue: string | number,
    oldValue: string | number | null,
    state: TabsCommonProps
  ) => void;
  containerClassNames?: string;
};

export type TabsComponents = {
  List: typeof TabsList;
  Panel: typeof TabPanel;
  Item: typeof Tab;
  PanelContainer: typeof TabsContainer;
};

export type TabsCommonProps = Omit<
  Required<TabsProps>,
  "children" | "containerClassNames"
>;

export const TabsContext = createContext<TabsCommonProps | null>(null);

const Tabs: React.FC<TabsProps> & TabsComponents = ({
  active,
  children,
  onChange,
  containerClassNames,
  ...rest
}) => {
  const [activeTab, setActiveTab] = useState(active);

  React.useEffect(() => {
    setActiveTab(active);
  }, [active]);

  const handleChangeTab: TabsCommonProps["onChange"] = (
    newValue,
    oldValue,
    state
  ) => {
    setActiveTab(newValue);
    onChange && onChange(newValue, oldValue, state);
  };

  return (
    <div className={classNames("w-100", containerClassNames)}>
      <TabsContext.Provider
        value={{
          active: activeTab,
          onChange: handleChangeTab,
          ...rest,
        }}
      >
        <div>{children}</div>
      </TabsContext.Provider>
    </div>
  );
};

Tabs.List = TabsList;
Tabs.Panel = TabPanel;
Tabs.Item = Tab;
Tabs.PanelContainer = TabsContainer;

export default Tabs;
