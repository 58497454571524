import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useMeasure, useScroll } from "react-use";

import styles from "./tabsList.module.css";
import { TabsContext } from "../tabs";

type TabsListProps = {
  children: string | React.ReactNode;
  ariaLabel: string;
  withOverflowGradient?: boolean;
  tabListClassnames?: string;
};

const END_OF_SCROLL_PADDING_WIDTH = 50;

export const TabsList = ({
  children,
  ariaLabel,
  withOverflowGradient = true,
  tabListClassnames,
}: TabsListProps) => {
  const tabsProps = useContext(TabsContext);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [wrapperRef, { width }] = useMeasure<HTMLDivElement>();
  const { x: scrollLeft } = useScroll(scrollRef);
  const childrenRef = useRef<HTMLButtonElement[]>([]);
  const [withOverflow, setWithOverflow] = useState(false);
  let childrenValues: (string | number)[] = [];

  useEffect(() => {
    if (withOverflowGradient) {
      if (
        scrollRef.current &&
        scrollLeft + width >
          scrollRef.current.scrollWidth - END_OF_SCROLL_PADDING_WIDTH
      ) {
        setWithOverflow(false);
      } else {
        setWithOverflow(true);
      }
    }
  }, [scrollRef, scrollLeft, width, withOverflowGradient]);

  if (!tabsProps) return null;

  React.Children.forEach(children, (child) => {
    React.isValidElement(child) && childrenValues.push(child!.props.value);
  });

  const handleNext = (currentValue: string | number) => {
    const currentIndex = childrenValues.indexOf(currentValue);
    let nextIndex = currentIndex + 1;

    if (nextIndex >= childrenValues.length) {
      nextIndex = 0;
    }

    childrenRef.current[nextIndex] && childrenRef.current[nextIndex].focus();
    tabsProps.onChange(childrenValues[nextIndex], currentValue, tabsProps);
  };

  const handlePrev = (currentValue: string | number) => {
    const currentIndex = childrenValues.indexOf(currentValue);
    let nextIndex = currentIndex - 1;

    if (nextIndex < 0) {
      nextIndex = childrenValues.length - 1;
    }

    childrenRef.current[nextIndex] && childrenRef.current[nextIndex].focus();
    tabsProps.onChange(childrenValues[nextIndex], currentValue, tabsProps);
  };

  return (
    <div
      className={classNames(
        styles.scrollableWrapper,
        "relative h-[52px] w-100 after:transition-opacity",
        {
          [styles.isVisibleOverflow]: withOverflow,
        }
      )}
      data-testid={"tabList__wrapper"}
      ref={wrapperRef}
    >
      <div
        role={"tablist"}
        aria-label={ariaLabel}
        ref={scrollRef}
        className={classNames(
          styles.scrollableArea,
          "flex flex-nowrap overflow-x-auto overflow-y-hidden border-y border-[#CACACC]",
          tabListClassnames
        )}
      >
        {React.Children.map(children, (child, index) => {
          return (
            React.isValidElement(child) &&
            React.cloneElement(child, {
              ...child.props,
              ref: (ref) => (childrenRef.current[index] = ref),
              onNext: handleNext,
              onPrev: handlePrev,
            })
          );
        })}
      </div>
    </div>
  );
};
