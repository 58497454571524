import dynamic from "next/dynamic";

import { HomepageDatoProps, NewsletterVariant } from "lib/types/homepage";
import NewsletterCardSkeleton from "./components/newsletterCardSkeleton/newsletterCardSkeleton";
import { useState } from "react";

const NewsletterCardInner = dynamic(
  () => import("./components/newsletterCardInner/newsletterCardInner"),
  {
    ssr: false,
  }
);

type NewsletterHomepageCardProps = NewsletterVariant &
  Pick<HomepageDatoProps, "newslettersProSubscribeUrl">;

export const NewsletterHomepageCard = (props: NewsletterHomepageCardProps) => {
  const [busy, setBusy] = useState(true);

  const onAllElementsLoaded = () => {
    setBusy(false);
  };

  return (
    <div
      className={"relative h-full"}
      aria-busy={busy ? "true" : undefined}
      data-testid={"newsletterHomepageCard"}
    >
      <NewsletterCardInner {...props} onLoad={onAllElementsLoaded} />
      <div className={"peer-[.is-active]:hidden"}>
        <NewsletterCardSkeleton />
      </div>
    </div>
  );
};

export default NewsletterHomepageCard;
