import { useState } from "react";

import Tabs from "components/tabs/tabs";
import Button from "components/buttonNew/button";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import { useHomepageData, useHomepageDatoProps } from "lib/store/homepage";
import { useSitewideOptionData } from "lib/store/sitewideOption";
import type { HomepageSector } from "lib/types/homepage";

import NewsGrid from "./newsGrid/newsGrid";
import { SidebarCollection } from "./sidebarCollection/sidebarCollection";
import LatestArticles from "./latestArticles/latestArticles";
import AdUnit from "./adUnit/adUnit";

type ActionProps<T extends {} = {}> = T & {
  url?: string;
  text?: string;
  component?: string;
  event?: string;
  subComponent?: string;
};

type MixPanelData = {
  [key: string]: any;
};

const Featured = () => {
  const { sectors, latestNews } = useHomepageData();
  const {
    featuredHeading,
    latestNewsCtaLink,
    latestNewsCtaText,
    latestNewsHeading,
  } = useHomepageDatoProps();
  const { advertisementHeading } = useSitewideOptionData();
  const [active, setActive] = useState<string>(sectors[0]?.name || "All");
  const handleChange = (newValue: string) => {
    setActive(newValue);
  };
  const { track } = useMixpanelTracking();

  const handleTabChange = async (sector: HomepageSector) => {
    await track("Homepage sector change", {
      "Click text": sector.name,
      Component: "Featured",
    });
  };

  const handleCtaClick = async ({ url, text, component }: ActionProps) => {
    await track("Homepage link click", {
      "Click link": url,
      "Click text": text,
      Component: component,
    });
  };

  const handleArticleClick = async ({
    properties,
    component,
    subComponent,
    event,
  }: ActionProps<{ properties: MixPanelData }>) => {
    event &&
      (await track(event, {
        ...properties,
        Component: component,
        SubComponent: subComponent,
      }));
  };

  return (
    <section
      className="max-w-[1140px] pb-[70px] pt-[30px] md:mx-auto lg:max-w-[1220px]"
      data-testid={"featured"}
    >
      {sectors && sectors.length > 0 && (
        <>
          <h2 className={"mb-3 hidden font-bold leading-[120%] lg:block"}>
            {featuredHeading}
          </h2>
          <div className={"mb-[30px] lg:max-w-[807px]"}>
            <Tabs active={active} onChange={handleChange}>
              <Tabs.List ariaLabel={"Featured sectors"}>
                {sectors.map((sector) => {
                  return (
                    <Tabs.Item
                      className={"ga-featured-sector-tab"}
                      key={sector.name}
                      value={sector.name}
                      onClick={async () => await handleTabChange(sector)}
                    >
                      {sector.name}
                    </Tabs.Item>
                  );
                })}
              </Tabs.List>
              <div
                className={
                  "grid gap-6 pt-8 lg:grid-flow-row-dense lg:grid-cols-[minmax(807px,_1fr)_392px] lg:gap-x-4"
                }
              >
                <div className={"mr-0 w-100 max-w-100 lg:mr-5"}>
                  <h2 className={"mb-3 font-bold leading-[120%] lg:hidden"}>
                    {featuredHeading}
                  </h2>
                  <Tabs.PanelContainer>
                    {sectors.map((sector) => {
                      return (
                        <Tabs.Panel value={sector.name} key={sector.name}>
                          <NewsGrid
                            gaArticleClassName={"ga-featured-article"}
                            gaArticleSectorClassName={
                              "ga-featured-article-sector"
                            }
                            articles={sector.featured}
                            onArticleClick={async (data, type) =>
                              await handleArticleClick({
                                event:
                                  type === "sector"
                                    ? "Homepage article sector click"
                                    : "Homepage article click",
                                properties: data,
                                component: "Featured",
                              })
                            }
                            onBriefingClick={async (data, type) =>
                              await handleArticleClick({
                                event:
                                  type === "labelLeft"
                                    ? "Homepage featured briefing sector click"
                                    : "Homepage featured briefing click",
                                properties: data,
                                component: "Featured",
                                subComponent: "Featured Briefing",
                              })
                            }
                          />
                        </Tabs.Panel>
                      );
                    })}
                  </Tabs.PanelContainer>
                </div>
                <div
                  className={
                    "grid w-100 grid-cols-1 grid-rows-[min-content] gap-8 lg:gap-10"
                  }
                >
                  <div
                    className={
                      "relative data-[ad-filled=false]:hidden lg:mt-[-1.875em]"
                    }
                    data-ad-slot={"ad_slot_0"}
                    data-ad-filled={true}
                  >
                    <h2
                      className={"mb-3 font-bold leading-[120%] opacity-0"}
                      id={"ad_slot_0_labeling"}
                    >
                      {advertisementHeading}
                    </h2>
                    <div className={"m-auto"}>
                      <div
                        className={
                          "m-auto flex h-[250px] w-[300px] items-center justify-center"
                        }
                      >
                        <AdUnit AdUnitID={"ad_slot_0"} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    {latestNews && (
                      <>
                        <SidebarCollection
                          gaArticleClassName={"ga-news-article"}
                          gaArticleSectorClassName={"ga-news-article-sector"}
                          collection={latestNews}
                          heading={latestNewsHeading}
                          onArticleClick={async (data, type) =>
                            await handleArticleClick({
                              event:
                                type === "sector"
                                  ? "Homepage article sector click"
                                  : "Homepage article click",
                              properties: data,
                              component: "Latest News",
                            })
                          }
                          emptyMessage={
                            "Something went wrong, we are already working on this problem"
                          }
                        />
                        <div
                          className={"mt-5 flex w-100 justify-center lg:hidden"}
                        >
                          <Button
                            className={"ga-news-more-link"}
                            size={"small"}
                            colour={"black"}
                            component={"link"}
                            href={latestNewsCtaLink}
                            onClick={async () => {
                              await handleCtaClick({
                                url: latestNewsCtaLink,
                                text: latestNewsCtaText,
                                component: "Latest News",
                              });
                            }}
                          >
                            {latestNewsCtaText}
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Tabs>
          </div>
        </>
      )}
      <LatestArticles
        gaMoreLinkClassName={"ga-latest-more-link"}
        gaArticleClassName={"ga-latest-article"}
        gaArticleSectorClassName={"ga-latest-article-sector"}
        onArticleClick={async (data, type) =>
          await handleArticleClick({
            event:
              type === "sector"
                ? "Homepage article sector click"
                : "Homepage article click",
            properties: data,
            component: "Latest Articles",
          })
        }
        onCtaButtonClick={async (data) =>
          await handleCtaClick({
            ...data,
            component: "Latest Articles",
          })
        }
      />
    </section>
  );
};

export default Featured;
