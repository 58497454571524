import classNames from "classnames";
import React, { Fragment } from "react";

import Image from "../imageNew/image";

import RootComponent from "./rootComponent/rootComponent";
import Button from "../buttonNew/button";
import type { FullDatoImage } from "../../lib/types/datoBlocks";

export type FeatureCardProps = {
  type?: "image" | "text-only";
  component: "button" | "link";
  title: string;
  subLiner?: string | null;
  wrapperClasses?: string;
  tag?: string;
  excerpt?: string;
  isSponsored?: boolean;
  leftLabel?: string;
  rightLabel?: string;
  sponsorInfo?: {
    name?: string;
    url?: string;
    logo?: FullDatoImage;
    sponsorshipType?:
      | "Sponsored by"
      | "In partnership with"
      | "Payed by"
      | null
      | undefined;
  }[];
  backgroundImage?: FullDatoImage & {
    sizes?: string;
  };
  withPaywall?: boolean;
  href?: string;
  paywallHeading?: string;
  onClick?: () => void;
  buttonText?: string;
  buttonProps?: { [key: string]: string | boolean };
  theme?: "default" | "pro";
  tagColor?: "black" | "cerise" | "grey";
  buttonOnClick?: () => void;
  disabled?: boolean;
  target?: string;
};

const FeatureCard = ({
  type = "image",
  component,
  title,
  wrapperClasses,
  tag,
  excerpt,
  subLiner,
  isSponsored,
  sponsorInfo,
  leftLabel,
  rightLabel,
  backgroundImage,
  withPaywall = false,
  href,
  paywallHeading = "Join to access",
  onClick,
  theme = "default",
  tagColor,
  buttonText,
  buttonProps,
  buttonOnClick,
  disabled = false,
  target,
}: FeatureCardProps) => {
  return (
    <RootComponent
      data-testid="feature-card"
      href={href}
      component={component}
      className={classNames(
        "group relative block h-full w-full bg-white text-left",
        "after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:opacity-0 after:transition-opacity after:content-['']",
        "focus:outline-none focus-visible:outline-none active:after:opacity-30",
        wrapperClasses,
        {
          "after:bg-cerise": theme === "default",
          "after:bg-pro-blue": theme === "pro",
        }
      )}
      onClick={onClick}
      data-color={theme === "default" ? "cerise" : "navy"}
      disabled={disabled}
      target={target}
    >
      <>
        {withPaywall && (
          <div
            className={classNames(
              "pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-10 h-full w-full opacity-0 transition-opacity duration-200",
              "group-hover:opacity-100 group-focus-visible:opacity-100"
            )}
          >
            <div
              className={classNames(
                "flex h-full w-full items-center justify-center bg-white bg-opacity-[0.94] font-bold text-[24px]",
                "group-hover:ring-3 group-focus-visible:ring-3 group-hover:ring group-hover:ring-inset group-focus-visible:ring group-focus-visible:ring-inset",
                {
                  "text-pro-blue ring-pro-blue": theme === "pro",
                  "text-cerise ring-cerise": theme === "default",
                }
              )}
            >
              {paywallHeading}
            </div>
          </div>
        )}
        {type === "image" && (
          <div
            data-testid={"featureCard__image"}
            className={classNames(
              "absolute bottom-0 left-0 right-0 top-0 z-[0] h-full w-full object-cover",
              {
                "bg-[#EBEBF0]": !backgroundImage || !backgroundImage.url,
                "bg-[#FFFFFF]": backgroundImage && backgroundImage.url,
              }
            )}
          >
            {backgroundImage && backgroundImage.url && (
              <Image
                src={backgroundImage.url}
                alt={backgroundImage.alt || ""}
                width={backgroundImage.width}
                height={backgroundImage.height}
                blurDataURL={backgroundImage.blurUpThumb || undefined}
                className={"pointer-events-none h-100 w-100 object-cover"}
                sizes={backgroundImage.sizes}
              />
            )}
          </div>
        )}
        {tag && (
          <div
            className={classNames(
              "absolute left-[12px] top-[12px] px-[6px] py-[6px] font-bold text-[14px] leading-[130%] text-white",
              {
                "bg-black-rock": tagColor === "black",
                "bg-mono-40": tagColor === "grey",
                "bg-cerise":
                  tagColor === "cerise" || (!tagColor && theme === "default"),
                "bg-pro-blue": !tagColor && theme === "pro",
              }
            )}
          >
            {tag}
          </div>
        )}
        <div
          className={classNames(
            "z-1 relative flex h-full p-3 transition-[box-shadow] ",
            "group-hover:ring-3 group-hover:ring-inset",
            "group-focus-visible:ring-3 group-focus-visible:outline-none group-focus-visible:ring group-focus-visible:ring-inset",
            {
              "items-end": type === "image",
              "group-hover:ring-cerise group-focus-visible:ring-cerise":
                !disabled && theme === "default",
              "group-hover:ring-pro-blue group-focus-visible:ring-pro-blue":
                !disabled && theme === "pro",
              "group-hover:ring": !disabled && !withPaywall,
            }
          )}
        >
          <div
            className={classNames("flex w-full flex-col bg-white", {
              "gap-[14px] p-[14px]": type === "image",
              "gap-[10px] p-0": type === "text-only",
            })}
          >
            <div className={"flex flex-col gap-[6px]"}>
              {(leftLabel || rightLabel) && (
                <div
                  className={"flex justify-between"}
                  data-testid={"featureCard__topMeta"}
                >
                  <div className={"text-[14px] text-mono-40"}>{leftLabel}</div>
                  <div
                    className={classNames("text-[14px]", {
                      "text-cerise": theme === "default",
                      "text-pro-blue": theme === "pro",
                    })}
                  >
                    {rightLabel}
                  </div>
                </div>
              )}
              <p
                className={classNames("font-recklessBold", {
                  "text-[22px] leading-[120%]": type === "image",
                  "text-[18px] leading-[130%]": type === "text-only",
                })}
              >
                {title}
              </p>
            </div>
            {excerpt && (
              <p
                className={
                  "line-clamp-3 text-[16px] leading-[130%] text-mono-70"
                }
              >
                {excerpt}
              </p>
            )}
            {subLiner && (
              <p
                className={
                  "line-clamp-1 text-[14px] leading-[130%] text-mono-40"
                }
              >
                {subLiner}
              </p>
            )}
            {isSponsored && sponsorInfo?.length ? (
              <div className="flex gap-x-[14px]">
                {sponsorInfo.map((sponsor, i) => (
                  <Fragment key={i}>
                    {sponsor.sponsorshipType && (
                      <div key={i} className={"flex flex-col"}>
                        <p className="mb-1 text-[12px] leading-[130%] text-mono-70">
                          {sponsor.sponsorshipType}
                        </p>
                        {sponsor.logo && (
                          <div className={"h-[36px] w-[90px] min-w-[90px]"}>
                            <Image
                              src={sponsor.logo.url}
                              alt={sponsor.logo.alt || `${sponsor.name} logo`}
                              width={sponsor.logo.width}
                              height={sponsor.logo.height}
                              blurDataURL={
                                sponsor.logo.blurUpThumb || undefined
                              }
                              className="pointer-events-none h-100 w-100 object-contain object-center"
                              sizes={"200px"}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            ) : null}
            {buttonText ? (
              <Button
                size={"small"}
                colour={theme === "default" ? "cerise" : "navy"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  buttonOnClick && buttonOnClick();
                }}
                {...buttonProps}
              >
                {buttonText}
              </Button>
            ) : null}
          </div>
        </div>
      </>
    </RootComponent>
  );
};

export default FeatureCard;
