import classNames from "classnames";

import { useHomepageData, useHomepageDatoProps } from "lib/store/homepage";
import ArticleCard from "components/articleCard/articleCard";
import Button from "components/buttonNew/button";
import TextButton from "components/textButton/textButton";

const MAX_TITLE_LENGTH_FOR_EXCERPT = 60;

type MixPanelData = {
  [key: string]: any;
};

type LatestArticlesProps = {
  onArticleClick: (data: MixPanelData, type: "url" | "sector") => Promise<void>;
  onCtaButtonClick: (data: { url: string; text: string }) => Promise<void>;
  gaArticleClassName?: string;
  gaArticleSectorClassName?: string;
  gaMoreLinkClassName?: string;
};

const LatestArticles = ({
  onArticleClick,
  onCtaButtonClick,
  gaArticleSectorClassName,
  gaArticleClassName,
  gaMoreLinkClassName,
}: LatestArticlesProps) => {
  const { latest } = useHomepageData();
  const {
    latestArticlesHeading,
    latestArticlesCtaLink,
    latestArticlesCtaText,
  } = useHomepageDatoProps();

  const handleCtaClick = async () => {
    await onCtaButtonClick({
      url: latestArticlesCtaLink,
      text: latestArticlesCtaText,
    });
  };

  if (!latest || latest.length < 5) return null;

  return (
    <div className="relative">
      <div className={"mb-3 flex items-center justify-between"}>
        <h2 className={"font-bold leading-[120%]"}>{latestArticlesHeading}</h2>
        <div className={"lg:hidden"}>
          <TextButton
            href={latestArticlesCtaLink}
            className={gaMoreLinkClassName}
            colour={"black"}
            withIcon={true}
            labelIcon={"Go to All Articles"}
            onClick={handleCtaClick}
          >
            {latestArticlesCtaText}
          </TextButton>
        </div>
      </div>
      <ul
        className={classNames(
          "grid auto-cols-min grid-cols-[repeat(5,_minmax(320px,_1fr))] gap-[10px] lg:grid-cols-3 lg:gap-5",
          "mx-[-20px] overflow-x-scroll pl-5 lg:mx-0 lg:overflow-hidden lg:pl-0"
        )}
        aria-label={`List of all the latest articles`}
      >
        {latest.map((article, index) => {
          return (
            <li
              key={article.id}
              className={classNames("h-[189px]", {
                "pr-5 lg:pr-0": index === latest.length - 1,
              })}
            >
              <ArticleCard
                mainLinkClasses={gaArticleClassName}
                sectorClasses={gaArticleSectorClassName}
                variant={"small"}
                withImage={article.layout !== "short_form"}
                withExcerpt={
                  article.layout === "short_form" &&
                  article.title.length < MAX_TITLE_LENGTH_FOR_EXCERPT
                }
                truncateAuthors={true}
                onTrackingEventClicked={onArticleClick}
                {...article}
              />
            </li>
          );
        })}
        <li className={"hidden items-center justify-center lg:flex"}>
          <Button
            className={gaMoreLinkClassName}
            size={"small"}
            colour={"black"}
            component={"link"}
            href={latestArticlesCtaLink}
            onClick={handleCtaClick}
          >
            {latestArticlesCtaText}
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default LatestArticles;
