import classNames from "classnames";
import { useRef, useEffect, useState } from "react";
import styles from "./marquee.module.css";
import { useIntersectedOnce } from "lib/hooks/useIntersectedOnce";

type MarqueeProps = {
  children: React.ReactNode[];
  animationSpeed?: number;
  outerContainerClasses?: string;
  marqueeContainerClasses?: string;
};

export const MarqueeContent = ({
  children,
  animationSpeed = 10,
  outerContainerClasses = "mt-3 flex w-100 overflow-hidden border border-x-0 border-y-[3px] border-black-rock py-[10px]",
  marqueeContainerClasses = classNames(
    styles["marquee-container"],
    "relative py-4"
  ),
  show = false,
}: MarqueeProps & { show: boolean }) => {
  const numberItems = children.length;
  const animationDuration = `${numberItems / (animationSpeed / 100)}s`;

  return (
    <article className={outerContainerClasses} data-testid="marquee">
      {show ? (
        <div className={marqueeContainerClasses}>
          <ul
            style={{ animationDuration }}
            className={classNames(
              styles.marquee,
              "flex [-webkit-user-select:none]"
            )}
          >
            {children}
          </ul>
          <ul
            style={{ animationDuration }}
            className={classNames(
              styles["marquee-copy"],
              "absolute top-0 flex py-4 [-webkit-user-select:none]"
            )}
          >
            {children}
          </ul>
        </div>
      ) : null}
    </article>
  );
};

/**
 * This is lazy loaded to work around a Safari bug that prevents images animated on-screen via
 * CSS from triggering the rendering of an image in the next/image component.
 * IMPORTANT: To make this pattern work, when using next/image within this component `priority`
 * must be set to `true`.
 */
export const Marquee = (props: MarqueeProps) => {
  const intersectionRef = useRef(null);
  const intersection = useIntersectedOnce(intersectionRef, {
    root: null,
    rootMargin: "600px",
    threshold: 0.1,
  });
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (intersection) setShow(true);
  }, [intersection]);

  return (
    <div ref={intersectionRef}>
      <MarqueeContent {...props} show={show} />
    </div>
  );
};
