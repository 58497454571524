import { useRef, useState } from "react";
import classNames from "classnames";
import { m, AnimatePresence, useInView } from "framer-motion";

import { PaginationDots } from "./paginationDots";
import { wrap } from "./lib";
import { useCarouselTimer } from "./hooks/useCarouselTimer";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export type SingleSlideType = {
  component: React.ReactNode;
  onClick: Function;
};

type SingleSlideCarouselProps = {
  slides: SingleSlideType[];
  belowSlides?: "dots" | "none";
  autoplay?: boolean;
  containerClasses?: string;
  slideClasses?: string;
  timerMS?: number;
};

/**
 * Add a `top` value to slideClasses to change the vertical position of the slides
 */
export const SingleSlideCarousel = ({
  slides,
  autoplay = true,
  containerClasses,
  slideClasses,
  timerMS = 5000,
}: SingleSlideCarouselProps) => {
  const ref = useRef(null);
  const wasDraggedRef = useRef(false);
  const isInView = useInView(ref);

  const [interactionCount, setInteractionCount] = useState(0);
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, slides.length, page);

  const paginate = (newDirection: number) => {
    setInteractionCount(interactionCount + 1);
    setPage([page + newDirection, newDirection]);
  };

  useCarouselTimer(
    autoplay,
    () => paginate(1),
    interactionCount,
    setInteractionCount,
    timerMS,
    isInView
  );

  return (
    <div ref={ref}>
      <div
        className={classNames(
          containerClasses,
          "relative mb-[9px] flex h-100 w-100 items-center justify-center overflow-hidden"
        )}
      >
        <AnimatePresence initial={false} custom={direction}>
          <m.div
            key={page}
            data-testid={`single-slide-${page || 0}`}
            className={classNames(slideClasses, "absolute top-0 h-100 w-100")}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onClick={() => {
              if (!wasDraggedRef.current && slides[imageIndex].onClick)
                slides[imageIndex].onClick();
            }}
            onDragStart={() => (wasDraggedRef.current = true)}
            onDragEnd={(_, { offset, velocity }) => {
              wasDraggedRef.current = false;
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            {slides[imageIndex].component}
          </m.div>
        </AnimatePresence>
      </div>

      <PaginationDots
        slides={slides}
        currentIndex={page}
        setCurrentIndex={setPage}
        type="single"
        interactionCount={interactionCount}
        setInteractionCount={setInteractionCount}
      />
    </div>
  );
};
