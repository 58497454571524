import Script from "next/script";
import { useEffect } from "react";

import type { AdConfigType } from "./adConfig";
import useSiftedMediaQueries from "lib/hooks/useSiftedMediaQueries";

const AdManager = ({ config }: { config: AdConfigType }) => {
  const { mobile, tablet, desktop } = useSiftedMediaQueries();

  useEffect(() => {
    if (config && window?.googletag?.pubads) {
      const hiddenResponsiveAds = window.googletag
        .pubads()
        ?.getSlots()
        ?.filter((el) => !el?.getResponseInformation());

      if (hiddenResponsiveAds && hiddenResponsiveAds.length) {
        window.googletag.pubads().refresh(hiddenResponsiveAds);
      }
    }
  }, [mobile, tablet, desktop, config]);

  return (
    <>
      <Script src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
      <Script
        id="ga-ad-script"
        dangerouslySetInnerHTML={{
          __html: `
            const adConfiguration = ${JSON.stringify(config)};
            window.googletag = window.googletag || {cmd: []};

            window.googletag.cmd.push(function() {

            adConfiguration.slots.forEach((slot) => {
              const adSlot = googletag.defineSlot('/21845971025/Siftedeu/${
                config.ad_unit
              }', slot.sizes, slot.slot_id);


              adSlot.addService(googletag.pubads());

              if (slot.targeting){
                slot.targeting.forEach((targetingParam) => {
                  adSlot.setTargeting(targetingParam[0], targetingParam[1]);
                });
              }
              if (slot.size_mapping) {
                let mappingBuilder = googletag.sizeMapping();
                slot.size_mapping.forEach((size) => {
                  mappingBuilder.addSize(size[0], size[1]);
                });

                const mapping = mappingBuilder.build();
                adSlot.defineSizeMapping(mapping);
              }
            });

            if (adConfiguration.page_targeting){
              adConfiguration.page_targeting.forEach((targetingParam) => {
                googletag.pubads().setTargeting(targetingParam[0], targetingParam[1]);
              });
            }

            window.googletag.pubads().addEventListener('slotRenderEnded',
              function(event) {
                if (event && event.slot && event.isEmpty) {
                  const id = event.slot.getSlotElementId();
                  const element = document.querySelector("[data-ad-slot='" + id + "']");
                  if (element && element.dataset && element.dataset.adFilled) {
                    element.setAttribute("data-ad-filled", false);
                  }
                }
                if (!event.advertiserId) return
                // These are Sifted's own advertiser IDs
                const inHouseAdvertiserIds = [5261510826, 4785713906, 5233425593]
                const slot = event.slot;
                if (!inHouseAdvertiserIds.includes(event.advertiserId)) {
                  const label_id = slot.getSlotElementId() + "_labeling";
                  const element = document.getElementById(label_id);
                  element.classList.remove("opacity-0");
                }
              }
            );

              window.googletag.pubads().enableSingleRequest();
              window.googletag.pubads().collapseEmptyDivs();
              window.googletag.enableServices();
            });
          `,
        }}
      />
    </>
  );
};

export default AdManager;
