import Balancer from "react-wrap-balancer";

import { useHomepageDatoProps } from "lib/store/homepage";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import Image from "components/imageNew/image";
import Button from "components/buttonNew/button";

export const Rankings = () => {
  const data = useHomepageDatoProps();
  const { track } = useMixpanelTracking();

  return (
    <section className="border-black-rock h-100 w-100 border-t-2 pb-[30px]">
      <div className="flex h-[45px] items-center">
        <h3>Rankings</h3>
      </div>
      <div className="mx-auto flex h-100 w-100 max-w-[100%] flex-col-reverse justify-between min-[550px]:max-md:flex-row min-[550px]:max-md:gap-x-[30px] md:h-[465px] md:w-auto lg:mx-0 lg:flex-row lg:gap-x-[30px]">
        <div className="w-[100%] lg:w-[540px]">
          <a href={data.rankingUrl} className="ga-ranking-link">
            <Image
              src={data.rankingImage.url}
              height={data.rankingImage.height}
              width={data.rankingImage.width}
              blurDataURL={data.rankingImage.blurUpThumb}
              alt={data.rankingImage.alt || ""}
              className="w-100 origin-top object-contain md:max-w-[340px] lg:h-auto lg:w-[540px] lg:max-w-[540px]"
            />
          </a>
        </div>
        <div className="flex max-w-[100%] flex-col md:w-100 lg:w-[320px]">
          <p className="font-bold text-[22px] leading-[130%] md:mt-[-0.3em] md:text-[30px] lg:mt-0 lg:text-[28px] lg:leading-[150%]">
            <Balancer>{data.rankingsText}</Balancer>
          </p>
          <Button
            component="link"
            colour="black"
            size={"small"}
            className="ga-rankings-more-link mt-[20px] w-fit lg:mt-[30px]"
            href={data.rankingsCtaUrl}
            onClick={async () => {
              await track("Homepage link click", {
                "Click text": data.rankingsCtaText,
                "Click link": data.rankingsCtaUrl,
                Component: "Rankings",
              });
            }}
          >
            {data.rankingsCtaText}
          </Button>
        </div>
      </div>
    </section>
  );
};
