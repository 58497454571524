import ArticleCard from "components/articleCard/articleCard";
import BaseCard from "components/baseCard/baseCard";
import { FeaturedArticle, FeaturedBriefing } from "lib/types/homepage";

type MixPanelData = {
  [key: string]: any;
};

type NewsGridProps = {
  articles: (FeaturedArticle | FeaturedBriefing)[];
  gaArticleClassName?: string;
  gaArticleSectorClassName?: string;
  onArticleClick: (data: MixPanelData, type: "url" | "sector") => Promise<void>;
  onBriefingClick: (
    data: MixPanelData,
    type: "url" | "labelLeft"
  ) => Promise<void>;
};

const NewsGrid = ({
  articles,
  onArticleClick,
  gaArticleClassName,
  gaArticleSectorClassName,
  onBriefingClick,
}: NewsGridProps) => {
  return (
    <div className={"w-100"}>
      <ul
        className={
          "grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-1 lg:gap-y-4"
        }
        aria-label={"List of featured articles"}
      >
        {articles.map((article) => {
          if (article.__typename === "ArticleRecord") {
            return (
              <li key={article.id} className={"flex h-[207px] lg:h-[270px]"}>
                <ArticleCard
                  mainLinkClasses={gaArticleClassName}
                  sectorClasses={gaArticleSectorClassName}
                  variant={"large"}
                  withExcerpt={true}
                  truncateAuthors={true}
                  onTrackingEventClicked={onArticleClick}
                  {...article}
                />
              </li>
            );
          } else if (article.__typename === "BriefingLinkRecord") {
            const briefing = article.briefing;
            return (
              <li key={article.id} className={"flex h-[207px] lg:h-[270px]"}>
                <BaseCard
                  contentSubscriptionLevel={
                    briefing.isProContent ? "pro" : "free"
                  }
                  title={briefing.title}
                  url={`/pro/briefings/${briefing.slug}`}
                  excerpt={briefing.subtitle}
                  withExcerpt={true}
                  authors={[]}
                  authorsLabel={
                    <span className={"text-[14px] leading-[120%] text-mono-30"}>
                      Exclusive to Pro Subscribers
                    </span>
                  }
                  leftLabel={{
                    name: briefing.marketLabel,
                    url: `/pro/briefings/${briefing.slug}`,
                  }}
                  rightLabel={{
                    name: "Briefing",
                  }}
                  publishTimestamp={briefing.publishTimestamp}
                  image={briefing.briefingImage}
                  withImage={true}
                  mainLinkClasses={gaArticleClassName}
                  leftLabelClasses={gaArticleSectorClassName}
                  variant={"large"}
                  truncateAuthors={true}
                  onTrackingEventClicked={onBriefingClick}
                />
              </li>
            );
          }

          return null;
        })}
      </ul>
    </div>
  );
};

export default NewsGrid;
